import * as yup from "yup";

export default yup.object().shape({
  name: yup.string().required("É necessário informar a razão social."),
  coverage: yup
    .number()
    .typeError("É necessário informar a cobertura.")
    .required("É necessário informar a cobertura.")
    .min(30, "É necessário que a cobertura seja ao menos 30."),
  fantasy_name: yup.string().required("É necessário informar o nome fantasia."),
  state_registration: yup
    .string()
    .required("É necessário informar a inscrição estadual."),
  cnpj: yup.string().required("É necessário informar o CNPJ"),
  used_erp: yup.string().required("É necessário informar o ERP."),
});
