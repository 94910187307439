import {
  Button,
  ButtonGroup, CircularProgress, Grid,
  TableCell as MuiTableCell,
  Table, TableBody,
  TableHead,
  TableRow, withStyles
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  CardHeaderToolbar,
  LoadingDialog
} from "../../../../_metronic/_partials/controls";
import {
  decimalMoney, formatStoresToId, getMonthsBetween, getYearsBetween, syncScrollBetween
} from "../../../../helpers";
import { useSelectedStores } from "../../../../hooks/stores";
import { dLessOneMoment } from "../../../../services/date-filters";
import { filters, getOrders } from "../../../../services/orders";
import ComparisonTooltip from "../../../Common/ComparisonTooltip";
import YearlyTableSideTitle from "../../YearlyTableSideTitle";
import { Yearly as SalesReceiptYearly } from "../SalesReceipt/Yearly";
import "./styles.scss";

const timeFilter = filters.time.LAST_TWO_YEARS_WITH_CURRENT_YEAR;

const TableCell = withStyles(() => ({
  root: {
    borderBottom: 0
  }
}))(MuiTableCell);

const FixedColumn = withStyles(theme => ({
  root: {
    width: 60,
    position: 'sticky',
    left: 0,
    background: '#fff',
  },
}))(TableCell);

const visions = {
  ANUAL: "anual",
  TRIMESTRAL: "trimestral"
};

function groupByYear(groups) {
  const years = getYearsBetween(timeFilter.start_date, timeFilter.end_date);
  const newGroups = years.reduce((carry, year) => {
    const yearDate = new Date(year, 0, 1);
    const startYearDate = moment(yearDate).startOf("year");
    const endYearDate = moment(yearDate).endOf("year");
    const months = getMonthsBetween(startYearDate, endYearDate, "M");
    carry[year] = months.reduce((carryMonths, month) => {
      carryMonths[month] = groups.filter(
        group =>
          group.year === parseInt(year) && group.month === parseInt(month)
      );

      return carryMonths;
    }, {});

    return carry;
  }, {});

  return newGroups;
}

function groupByQuarter(groups) {
  const sumOfQuarter = (year, quarter) => {
    const quarterGroups = groups.filter(group => {
      return (
        parseInt(year) === group.year &&
        quarter === moment(new Date(group.year, group.month - 1)).quarter()
      );
    });

    const totalEntries = getSumOfColumn(quarterGroups, "entries");
    const totalCost = getSumOfColumn(quarterGroups, "total_products_cost");

    return {
      discount: getSumOfColumn(quarterGroups, "discount"),
      gross_profit: getSumOfColumn(quarterGroups, "gross_profit"),
      total_period_value: getSumOfColumn(quarterGroups, "total_period_value"),
      total_products_cost: totalCost,
      total_without_discounts: getSumOfColumn(
        quarterGroups,
        "total_value_without_discounts"
      ),
      entries: totalEntries,
      entries_without_cmv: totalEntries - totalCost
    };
  };

  const years = getYearsBetween(timeFilter.start_date, timeFilter.end_date);
  const newGroups = years.reduce((carry, year) => {
    carry[year] = [1, 2, 3, 4].reduce((carryQuarters, quarter) => {
      carryQuarters[quarter] = sumOfQuarter(year, quarter);

      return carryQuarters;
    }, {});

    return carry;
  }, {});

  return newGroups;
}

function getGrossMargin(cost, value) {
  if (!value) {
    return 0;
  }

  return ((value - cost) / value) * 100;
}

function getMarkUp(cost, value) {
  if (!cost) {
    return 0;
  }

  return value / cost;
}

function getSumOfColumn(groups, column) {
  return groups.reduce((carry, group) => {
    return carry + parseFloat(group[column]);
  }, 0);
}

function getSumOfYear(groups, column) {
  return groups.reduce((carry, month) => {
    return carry + getSumOfColumn(month, column);
  }, 0);
}

function getMonthGrossMargin(month) {
  const totalValue = getSumOfColumn(month, "total_period_value");
  const totalCost = getSumOfColumn(month, "total_products_cost");

  if (totalValue === 0) {
    return 0;
  }

  return getGrossMargin(totalCost, totalValue);
}

function getYearGrossMargin(year) {
  const totalValue = getSumOfYear(Object.values(year), "total_period_value");
  const totalCost = getSumOfYear(Object.values(year), "total_products_cost");
  if (totalValue === 0) {
    return 0;
  }

  return getGrossMargin(totalCost, totalValue);
}

function getMonthMarkUp(month) {
  const totalValue = getSumOfColumn(month, "total_period_value");
  const totalCost = getSumOfColumn(month, "total_products_cost");

  return getMarkUp(totalCost, totalValue);
}

function getYearMarkUp(year) {
  const totalValue = getSumOfYear(Object.values(year), "total_period_value");
  const totalCost = getSumOfYear(Object.values(year), "total_products_cost");

  return getMarkUp(totalCost, totalValue);
}

function getMonthEntriesWithoutCmv(month) {
  const totalEntries = getSumOfColumn(month, "entries");
  const totalCost = getSumOfColumn(month, "total_products_cost");

  return totalEntries - totalCost;
}

function getYearEntriesWithoutCmv(year) {
  const totalEntries = getSumOfYear(Object.values(year), "entries");
  const totalCost = getSumOfYear(Object.values(year), "total_products_cost");

  return totalEntries - totalCost;
}

export default function Yearly({ vision, setVision }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const selectedStores = useSelectedStores();
  const groups = data?.groups ? groupByYear(data.groups) : {};
  const quarterGroups = data?.groups ? groupByQuarter(data.groups) : {};
  const anualOrdersContainerRef = useRef();
  const stockContainerRef = useRef();
  const trimestralOrdersContainerRef = useRef();
  const trimestralStockContainerRef = useRef();

  const fetchData = async () => {
    setLoading(true);

    const params = { ...timeFilter };

    if (selectedStores.length) {
      params.stores = formatStoresToId(selectedStores).join(",");
    }

    try {
      const res = await getOrders(params);

      setData(res.data);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [selectedStores]);

  const quartersTableHead = (
    <TableHead className="table-head-row">
      <TableRow style={{ backgroundColor: '#fff' }}>
        <TableCell />
        <TableCell>1º trimestre</TableCell>
        <TableCell>2º trimestre</TableCell>
        <TableCell>3º trimestre</TableCell>
        <TableCell>4º trimestre</TableCell>
        <TableCell>Total</TableCell>
      </TableRow>
    </TableHead>
  );

  const resultsTitles = [
    { title: "Vendas Totais (R$)" },
    {
      title: "DDT (R$)",
      tooltip: "Descontos, Trocas e Devoluções",
      negative: true
    },
    {
      title: "Vendas (R$) - (DDT)",
      tooltip: "Vendas - Descontos, Trocas e Devoluções"
    },
    {
      title: "CMV (R$)",
      tooltip: "Custo de Mercadoria Vendida",
      negative: true
    },
    { title: "Lucro Bruto (R$)" },
    { title: "Margem Bruta", tooltip: "%" },
    { title: "Mark Up", tooltip: "Bruto" }
  ];

  useEffect(() => {
    if (data && !loading) {
      // syncronize scroll
      const ordersContainer = anualOrdersContainerRef.current;
      const stockContainer = stockContainerRef.current;
      const trimestralOrdersContainer = trimestralOrdersContainerRef.current;
      const trimestralStockContainer = trimestralStockContainerRef.current;

      if (ordersContainer) {
        syncScrollBetween(
          ordersContainer, document.querySelector('#anual-orders-header')
        );
      }

      if (stockContainer) {
        syncScrollBetween(
          stockContainer, document.querySelector('#anual-stock-header')
        );
      }

      if (trimestralOrdersContainer) {
        syncScrollBetween(
          trimestralOrdersContainer, document.querySelector('#trimestral-orders-header')
        );
      }

      if (trimestralStockContainer) {
        syncScrollBetween(
          trimestralStockContainer, document.querySelector('#trimestral-stock-header')
        );
      }
    }
  }, [data, loading, vision]);

  return (
    <>
      <LoadingDialog isLoading={loading} text="Carregando..." />
      <Grid item xs={12} className={`${clsx('printable-container', { cardContainer: !loading })}`}>
        <Card className="card-border">
          <CardHeader
            title={
              <>
                <CardHeaderTitle>
                  <span className="mt-4">
                    Resultado de Vendas - CMV - Lucro Bruto
                  </span>
                </CardHeaderTitle>
                <div className="font-size-sm text-muted mt-2">
                  Aqui estão os resultados das vendas dos anos de{" "}
                  {dLessOneMoment.format("YYYY") - 2},{" "}
                  {dLessOneMoment.format("YYYY") - 1} e{" "}
                  {dLessOneMoment.format("YYYY")} até{" "}
                  {dLessOneMoment.format("MMMM").toLowerCase()} com os
                  indicadores de variação de desempenho, tanto na visão
                  trimestral como anual.
                </div>
              </>
            }
            toolbar={
              <CardHeaderToolbar className="d-print-none">
                <ButtonGroup>
                  <Button
                    color="secondary"
                    onClick={() => setVision(visions.ANUAL)}
                    variant={
                      vision === visions.ANUAL ? "contained" : "outlined"
                    }
                  >
                    Anual
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => setVision(visions.TRIMESTRAL)}
                    variant={
                      vision === visions.TRIMESTRAL ? "contained" : "outlined"
                    }
                  >
                    Trimestral
                  </Button>
                </ButtonGroup>
              </CardHeaderToolbar>
            }
          />

          <CardBody>
            {loading && (
              <div className="loading-container">
                <CircularProgress />
              </div>
            )}
            {!loading &&
              (vision === "anual" ? (
                <>
                <div class="theader" id="anual-orders-header">
                  <div><strong>Jan</strong></div>
                  <div><strong>Fev</strong></div>
                  <div><strong>Mar</strong></div>
                  <div><strong>Abr</strong></div>
                  <div><strong>Mai</strong></div>
                  <div><strong>Jun</strong></div>
                  <div><strong>Jul</strong></div>
                  <div><strong>Ago</strong></div>
                  <div><strong>Set</strong></div>
                  <div><strong>Out</strong></div>
                  <div><strong>Nov</strong></div>
                  <div><strong>Dez</strong></div>
                  <div><strong>Total</strong></div>
                </div>
                <div className="d-flex">
                  <YearlyTableSideTitle titles={resultsTitles} />

                  <div className="overflow-auto" ref={anualOrdersContainerRef}>
                      <Table className="subtable">
                      
                      <TableBody className="yearly-table-body">
                        {Object.keys(groups).map(year => (
                          <TableRow key={year}>
                            <FixedColumn>
                              <strong>{year}</strong>
                            </FixedColumn>
                            {Object.keys(groups[year]).map(month => (
                              <TableCell
                                key={`${year}-${month}`}
                                style={{
                                  minWidth: 150,
                                  backgroundColor: "#f7f7f7"
                                }}
                              >
                                <div className="section-container">
                                  <span className="mr-2">
                                    {decimalMoney(
                                      getSumOfColumn(
                                        groups[year][month],
                                        "total_period_value"
                                      )
                                    )}
                                  </span>

                                  {groups[year - 1] !== undefined && (
                                    <ComparisonTooltip
                                      a={getSumOfColumn(
                                        groups[year][month],
                                        "total_period_value"
                                      )}
                                      b={getSumOfColumn(
                                        groups[year - 1][month],
                                        "total_period_value"
                                      )}
                                    />
                                  )}
                                </div>
                              </TableCell>
                            ))}
                            <TableCell style={{ backgroundColor: "#f7f7f7", minWidth: 150 }}>
                              <div className="section-container">
                                <span className="mr-2">
                                  {decimalMoney(
                                    getSumOfYear(
                                      Object.values(groups[year]),
                                      "total_period_value"
                                    )
                                  )}
                                </span>

                                {groups[year - 1] !== undefined && (
                                  <ComparisonTooltip
                                    a={getSumOfYear(
                                      Object.values(groups[year]),
                                      "total_period_value"
                                    )}
                                    b={getSumOfYear(
                                      Object.values(groups[year - 1]),
                                      "total_period_value"
                                    )}
                                  />
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Table className="subtable">
                      <TableBody className="yearly-table-body">
                        {Object.keys(groups).map(year => (
                          <TableRow key={year}>
                            <FixedColumn>
                              <strong>{year}</strong>
                            </FixedColumn>
                            {Object.keys(groups[year]).map(month => (
                              <TableCell
                                key={`${year}-${month}`}
                                style={{
                                  minWidth: 150,
                                  backgroundColor: "#f7f7f7"
                                }}
                              >
                                <div className="section-container">
                                  <span className="mr-2 text-dark-danger">
                                    {getSumOfColumn(
                                      groups[year][month],
                                      "discount"
                                    ) > 0
                                      ? "-" +
                                        decimalMoney(
                                          getSumOfColumn(
                                            groups[year][month],
                                            "discount"
                                          )
                                        )
                                      : decimalMoney(
                                          getSumOfColumn(
                                            groups[year][month],
                                            "discount"
                                          )
                                        )}
                                  </span>

                                  {groups[year - 1] !== undefined && (
                                    <ComparisonTooltip
                                      a={getSumOfColumn(
                                        groups[year][month],
                                        "discount"
                                      )}
                                      b={getSumOfColumn(
                                        groups[year - 1][month],
                                        "discount"
                                      )}
                                    />
                                  )}
                                </div>
                              </TableCell>
                            ))}
                            <TableCell style={{ backgroundColor: "#f7f7f7", minWidth: 150 }}>
                              <div className="section-container">
                                <span className="mr-2 text-dark-danger">
                                  {getSumOfYear(
                                    Object.values(groups[year]),
                                    "discount"
                                  ) > 0
                                    ? "-" +
                                      decimalMoney(
                                        getSumOfYear(
                                          Object.values(groups[year]),
                                          "discount"
                                        )
                                      )
                                    : decimalMoney(
                                        getSumOfYear(
                                          Object.values(groups[year]),
                                          "discount"
                                        )
                                      )}
                                </span>

                                {groups[year - 1] !== undefined && (
                                  <ComparisonTooltip
                                    a={getSumOfYear(
                                      Object.values(groups[year]),
                                      "discount"
                                    )}
                                    b={getSumOfYear(
                                      Object.values(groups[year - 1]),
                                      "discount"
                                    )}
                                  />
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <Table className="subtable">
                      <TableBody className="yearly-table-body">
                        {Object.keys(groups).map(year => (
                          <TableRow key={year}>
                            <FixedColumn>
                              <strong>{year}</strong>
                            </FixedColumn>
                            {Object.keys(groups[year]).map(month => (
                              <TableCell
                                key={`${year}-${month}`}
                                style={{
                                  minWidth: 150,
                                  backgroundColor: "#f7f7f7"
                                }}
                              >
                                <div className="section-container">
                                  <span className="mr-2">
                                    {decimalMoney(
                                      getSumOfColumn(
                                        groups[year][month],
                                        "total_value_without_discounts"
                                      ).toFixed(2)
                                    )}
                                  </span>

                                  {groups[year - 1] !== undefined && (
                                    <ComparisonTooltip
                                      a={getSumOfColumn(
                                        groups[year][month],
                                        "total_value_without_discounts"
                                      )}
                                      b={getSumOfColumn(
                                        groups[year - 1][month],
                                        "total_value_without_discounts"
                                      )}
                                    />
                                  )}
                                </div>
                              </TableCell>
                            ))}
                            <TableCell style={{ backgroundColor: "#f7f7f7", minWidth: 150 }}>
                              <div className="section-container">
                                <span className="mr-2">
                                  {decimalMoney(
                                    getSumOfYear(
                                      Object.values(groups[year]),
                                      "total_value_without_discounts"
                                    )
                                  )}
                                </span>

                                {groups[year - 1] !== undefined && (
                                  <ComparisonTooltip
                                    a={getSumOfYear(
                                      Object.values(groups[year]),
                                      "total_value_without_discounts"
                                    )}
                                    b={getSumOfYear(
                                      Object.values(groups[year - 1]),
                                      "total_value_without_discounts"
                                    )}
                                  />
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <Table className="subtable">
                      <TableBody className="yearly-table-body">
                        {Object.keys(groups).map(year => (
                          <TableRow key={year}>
                            <FixedColumn>
                              <strong>{year}</strong>
                            </FixedColumn>
                            {Object.keys(groups[year]).map(month => (
                              <TableCell
                                key={`${year}-${month}`}
                                style={{
                                  minWidth: 150,
                                  backgroundColor: "#f7f7f7"
                                }}
                              >
                                <div className="section-container">
                                  <span className="mr-2 text-dark-danger">
                                    {getSumOfColumn(
                                      groups[year][month],
                                      "total_products_cost"
                                    ) > 0
                                      ? "-" +
                                        decimalMoney(
                                          getSumOfColumn(
                                            groups[year][month],
                                            "total_products_cost"
                                          ).toFixed(2)
                                        )
                                      : decimalMoney(
                                          getSumOfColumn(
                                            groups[year][month],
                                            "total_products_cost"
                                          ).toFixed(2)
                                        )}
                                  </span>

                                  {groups[year - 1] !== undefined && (
                                    <ComparisonTooltip
                                      a={getSumOfColumn(
                                        groups[year][month],
                                        "total_products_cost"
                                      )}
                                      b={getSumOfColumn(
                                        groups[year - 1][month],
                                        "total_products_cost"
                                      )}
                                    />
                                  )}
                                </div>
                              </TableCell>
                            ))}
                            <TableCell style={{ backgroundColor: "#f7f7f7", minWidth: 150 }}>
                              <div className="section-container">
                                <span className="mr-2 text-dark-danger">
                                  {getSumOfYear(
                                    Object.values(groups[year]),
                                    "total_products_cost"
                                  )
                                    ? "-" +
                                      decimalMoney(
                                        getSumOfYear(
                                          Object.values(groups[year]),
                                          "total_products_cost"
                                        )
                                      )
                                    : decimalMoney(
                                        getSumOfYear(
                                          Object.values(groups[year]),
                                          "total_products_cost"
                                        )
                                      )}
                                </span>

                                {groups[year - 1] !== undefined && (
                                  <ComparisonTooltip
                                    a={getSumOfYear(
                                      Object.values(groups[year]),
                                      "total_products_cost"
                                    )}
                                    b={getSumOfYear(
                                      Object.values(groups[year - 1]),
                                      "total_products_cost"
                                    )}
                                  />
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <Table className="subtable">
                      <TableBody className="yearly-table-body">
                        {Object.keys(groups).map(year => (
                          <TableRow key={year}>
                            <FixedColumn>
                              <strong>{year}</strong>
                            </FixedColumn>
                            {Object.keys(groups[year]).map(month => (
                              <TableCell
                                key={`${year}-${month}`}
                                style={{
                                  minWidth: 150,
                                  backgroundColor: "#f7f7f7"
                                }}
                              >
                                <div className="section-container">
                                  <span className="mr-2">
                                    {decimalMoney(
                                      getSumOfColumn(
                                        groups[year][month],
                                        "gross_profit"
                                      )
                                    )}
                                  </span>

                                  {groups[year - 1] !== undefined && (
                                    <ComparisonTooltip
                                      a={getSumOfColumn(
                                        groups[year][month],
                                        "gross_profit"
                                      )}
                                      b={getSumOfColumn(
                                        groups[year - 1][month],
                                        "gross_profit"
                                      )}
                                    />
                                  )}
                                </div>
                              </TableCell>
                            ))}
                            <TableCell style={{ backgroundColor: "#f7f7f7", minWidth: 150 }}>
                              <div className="section-container">
                                <span className="mr-2">
                                  {decimalMoney(
                                    getSumOfYear(
                                      Object.values(groups[year]),
                                      "gross_profit"
                                    )
                                  )}
                                </span>

                                {groups[year - 1] !== undefined && (
                                  <ComparisonTooltip
                                    a={getSumOfYear(
                                      Object.values(groups[year]),
                                      "gross_profit"
                                    )}
                                    b={getSumOfYear(
                                      Object.values(groups[year]),
                                      "gross_profit"
                                    )}
                                  />
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <Table className="subtable">
                      <TableBody className="yearly-table-body">
                        {Object.keys(groups).map(year => (
                          <TableRow key={year}>
                            <FixedColumn>
                              <strong>{year}</strong>
                            </FixedColumn>
                            {Object.keys(groups[year]).map(month => (
                              <TableCell
                                key={`${year}-${month}`}
                                style={{
                                  minWidth: 150,
                                  backgroundColor: "#f7f7f7"
                                }}
                              >
                                <div className="section-container">
                                  <span className="mr-2">
                                    {getMonthGrossMargin(
                                      groups[year][month]
                                    ).toFixed(2)}
                                  </span>

                                  {groups[year - 1] !== undefined && (
                                    <ComparisonTooltip
                                      a={getMonthGrossMargin(
                                        groups[year][month]
                                      )}
                                      b={getMonthGrossMargin(
                                        groups[year - 1][month]
                                      )}
                                    />
                                  )}
                                </div>
                              </TableCell>
                            ))}
                            <TableCell style={{ backgroundColor: "#f7f7f7", minWidth: 150 }}>
                              <div className="section-container">
                                <span className="mr-2">
                                  {getYearGrossMargin(groups[year]).toFixed(2)}
                                </span>

                                {groups[year - 1] !== undefined && (
                                  <ComparisonTooltip
                                    a={getYearGrossMargin(groups[year])}
                                    b={getYearGrossMargin(groups[year - 1])}
                                  />
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <Table className="subtable">
                      <TableBody className="yearly-table-body">
                        {Object.keys(groups).map(year => (
                          <TableRow key={year}>
                            <FixedColumn>
                              <strong>{year}</strong>
                            </FixedColumn>
                            {Object.keys(groups[year]).map(month => (
                              <TableCell
                                key={`${year}-${month}`}
                                style={{
                                  minWidth: 150,
                                  backgroundColor: "#f7f7f7"
                                }}
                              >
                                <div className="section-container">
                                  <span className="mr-2">
                                    {getMonthMarkUp(
                                      groups[year][month]
                                    ).toFixed(2)}
                                  </span>

                                  {groups[year - 1] !== undefined && (
                                    <ComparisonTooltip
                                      a={getMonthMarkUp(groups[year][month])}
                                      b={getMonthMarkUp(
                                        groups[year - 1][month]
                                      )}
                                    />
                                  )}
                                </div>
                              </TableCell>
                            ))}
                            <TableCell style={{ backgroundColor: "#f7f7f7", minWidth: 150 }}>
                              <div className="section-container">
                                <span className="mr-2">
                                  {getYearMarkUp(groups[year]).toFixed(2)}
                                </span>

                                {groups[year - 1] !== undefined && (
                                  <ComparisonTooltip
                                    a={getYearMarkUp(groups[year])}
                                    b={getYearMarkUp(groups[year - 1])}
                                  />
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
                </>
              ) : (
                <>
                  <div class="theader" id="trimestral-orders-header" style={{
                    maxWidth: 750
                  }}>
                    <div><strong>1º trimestre</strong></div>
                    <div><strong>2º trimestre</strong></div>
                    <div><strong>3º trimestre</strong></div>
                    <div><strong>4º trimestre</strong></div>
                    <div><strong>Total</strong></div>
                  </div>
                  <div className="d-flex">
                    <YearlyTableSideTitle titles={resultsTitles} />

                    <div className="overflow-auto" ref={trimestralOrdersContainerRef}>
                      <Table className="subtable">

                        <TableBody className="yearly-table-body">
                          {Object.keys(quarterGroups).map(year => (
                            <TableRow key={year}>
                              <FixedColumn>
                                <strong>{year}</strong>
                              </FixedColumn>
                              {Object.keys(quarterGroups[year]).map(quarter => (
                                <TableCell
                                  key={`${year}-${quarter}`}
                                  style={{
                                    minWidth: 150,
                                    backgroundColor: "#f7f7f7"
                                  }}
                                >
                                  <div className="section-container">
                                    <span className="mr-2">
                                      {decimalMoney(
                                        quarterGroups[year][quarter]
                                          .total_period_value
                                      )}
                                    </span>

                                    {quarterGroups[year - 1] !== undefined && (
                                      <ComparisonTooltip
                                        a={
                                          quarterGroups[year][quarter]
                                            .total_period_value
                                        }
                                        b={
                                          quarterGroups[year - 1][quarter]
                                            .total_period_value
                                        }
                                      />
                                    )}
                                  </div>
                                </TableCell>
                              ))}
                              <TableCell style={{ backgroundColor: "#f7f7f7", minWidth: 150 }}>
                                <div className="section-container">
                                  <span className="mr-2">
                                    {decimalMoney(
                                      getSumOfYear(
                                        Object.values(groups[year]),
                                        "total_period_value"
                                      )
                                    )}
                                  </span>

                                  {quarterGroups[year - 1] !== undefined && (
                                    <ComparisonTooltip
                                      a={getSumOfYear(
                                        Object.values(groups[year]),
                                        "total_period_value"
                                      )}
                                      b={getSumOfYear(
                                        Object.values(groups[year - 1]),
                                        "total_period_value"
                                      )}
                                    />
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>

                      <Table className="subtable">
                        <TableBody className="yearly-table-body">
                          {Object.keys(quarterGroups).map(year => (
                            <TableRow key={year}>
                              <FixedColumn>
                                <strong>{year}</strong>
                              </FixedColumn>
                              {Object.keys(quarterGroups[year]).map(quarter => (
                                <TableCell
                                  key={`${year}-${quarter}`}
                                  style={{
                                    minWidth: 150,
                                    backgroundColor: "#f7f7f7"
                                  }}
                                >
                                  <div className="d-flex align-items-center text-dark-danger">
                                    <span className="mr-2">
                                      {decimalMoney(
                                        quarterGroups[year][quarter].discount
                                      )}
                                    </span>

                                    {quarterGroups[year - 1] !== undefined && (
                                      <ComparisonTooltip
                                        a={quarterGroups[year][quarter].discount}
                                        b={
                                          quarterGroups[year - 1][quarter]
                                            .discount
                                        }
                                      />
                                    )}
                                  </div>
                                </TableCell>
                              ))}
                              <TableCell style={{ backgroundColor: "#f7f7f7", minWidth: 150 }}>
                                <div className="d-flex text-dark-danger">
                                  <span className="mr-2">
                                    {decimalMoney(
                                      getSumOfYear(
                                        Object.values(groups[year]),
                                        "discount"
                                      )
                                    )}
                                  </span>

                                  {quarterGroups[year - 1] !== undefined && (
                                    <ComparisonTooltip
                                      a={getSumOfYear(
                                        Object.values(groups[year]),
                                        "discount"
                                      )}
                                      b={getSumOfYear(
                                        Object.values(groups[year - 1]),
                                        "discount"
                                      )}
                                    />
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>

                      <Table className="subtable">
                        <TableBody className="yearly-table-body">
                          {Object.keys(quarterGroups).map(year => (
                            <TableRow key={year}>
                              <FixedColumn>
                                <strong>{year}</strong>
                              </FixedColumn>
                              {Object.keys(quarterGroups[year]).map(quarter => (
                                <TableCell
                                  key={`${year}-${quarter}`}
                                  style={{
                                    minWidth: 150,
                                    backgroundColor: "#f7f7f7"
                                  }}
                                >
                                  <div className="section-container">
                                    <span className="mr-2">
                                      {decimalMoney(
                                        quarterGroups[year][quarter]
                                          .total_without_discounts
                                      )}
                                    </span>

                                    {quarterGroups[year - 1] !== undefined && (
                                      <ComparisonTooltip
                                        a={
                                          quarterGroups[year][quarter]
                                            .total_without_discounts
                                        }
                                        b={
                                          quarterGroups[year - 1][quarter]
                                            .total_without_discounts
                                        }
                                      />
                                    )}
                                  </div>
                                </TableCell>
                              ))}
                              <TableCell style={{ backgroundColor: "#f7f7f7", minWidth: 150 }}>
                                <div className="section-container">
                                  <span className="mr-2">
                                    {decimalMoney(
                                      getSumOfYear(
                                        Object.values(groups[year]),
                                        "total_value_without_discounts"
                                      )
                                    )}
                                  </span>

                                  {quarterGroups[year - 1] !== undefined && (
                                    <ComparisonTooltip
                                      a={getSumOfYear(
                                        Object.values(groups[year]),
                                        "total_value_without_discounts"
                                      )}
                                      b={getSumOfYear(
                                        Object.values(groups[year - 1]),
                                        "total_value_without_discounts"
                                      )}
                                    />
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Table className="subtable">
                        <TableBody className="yearly-table-body">
                          {Object.keys(quarterGroups).map(year => (
                            <TableRow key={year}>
                              <FixedColumn>
                                <strong>{year}</strong>
                              </FixedColumn>
                              {Object.keys(quarterGroups[year]).map(quarter => (
                                <TableCell
                                  key={`${year}-${quarter}`}
                                  style={{
                                    minWidth: 150,
                                    backgroundColor: "#f7f7f7"
                                  }}
                                >
                                  <div className="d-flex align-items-center text-dark-danger">
                                    <span className="mr-2">
                                      {decimalMoney(
                                        quarterGroups[year][quarter]
                                          .total_products_cost
                                      )}
                                    </span>

                                    {quarterGroups[year - 1] !== undefined && (
                                      <ComparisonTooltip
                                        a={
                                          quarterGroups[year][quarter]
                                            .total_products_cost
                                        }
                                        b={
                                          quarterGroups[year - 1][quarter]
                                            .total_products_cost
                                        }
                                      />
                                    )}
                                  </div>
                                </TableCell>
                              ))}
                              <TableCell style={{ backgroundColor: "#f7f7f7", minWidth: 150 }}>
                                <div className="d-flex text-dark-danger">
                                  <span className="mr-2">
                                    {decimalMoney(
                                      getSumOfYear(
                                        Object.values(groups[year]),
                                        "total_products_cost"
                                      )
                                    )}
                                  </span>

                                  {quarterGroups[year - 1] !== undefined && (
                                    <ComparisonTooltip
                                      a={getSumOfYear(
                                        Object.values(groups[year]),
                                        "total_products_cost"
                                      )}
                                      b={getSumOfYear(
                                        Object.values(groups[year - 1]),
                                        "total_products_cost"
                                      )}
                                    />
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>

                      <Table className="subtable">
                        <TableBody className="yearly-table-body">
                          {Object.keys(quarterGroups).map(year => (
                            <TableRow key={year}>
                              <FixedColumn>
                                <strong>{year}</strong>
                              </FixedColumn>
                              {Object.keys(quarterGroups[year]).map(quarter => (
                                <TableCell
                                  key={`${year}-${quarter}`}
                                  style={{
                                    minWidth: 150,
                                    backgroundColor: "#f7f7f7"
                                  }}
                                >
                                  <div className="section-container">
                                    <span className="mr-2">
                                      {decimalMoney(
                                        quarterGroups[year][quarter].gross_profit
                                      )}
                                    </span>

                                    {quarterGroups[year - 1] !== undefined && (
                                      <ComparisonTooltip
                                        a={
                                          quarterGroups[year][quarter]
                                            .gross_profit
                                        }
                                        b={
                                          quarterGroups[year - 1][quarter]
                                            .gross_profit
                                        }
                                      />
                                    )}
                                  </div>
                                </TableCell>
                              ))}
                              <TableCell style={{ backgroundColor: "#f7f7f7", minWidth: 150 }}>
                                <div className="section-container">
                                  <span className="mr-2">
                                    {decimalMoney(
                                      getSumOfYear(
                                        Object.values(groups[year]),
                                        "gross_profit"
                                      )
                                    )}
                                  </span>

                                  {quarterGroups[year - 1] !== undefined && (
                                    <ComparisonTooltip
                                      a={getSumOfYear(
                                        Object.values(groups[year]),
                                        "gross_profit"
                                      )}
                                      b={getSumOfYear(
                                        Object.values(groups[year - 1]),
                                        "gross_profit"
                                      )}
                                    />
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>

                      <Table className="subtable">
                        <TableBody className="yearly-table-body">
                          {Object.keys(quarterGroups).map(year => (
                            <TableRow key={year}>
                              <FixedColumn>
                                <strong>{year}</strong>
                              </FixedColumn>
                              {Object.keys(quarterGroups[year]).map(quarter => (
                                <TableCell
                                  key={`${year}-${quarter}`}
                                  style={{
                                    minWidth: 150,
                                    backgroundColor: "#f7f7f7"
                                  }}
                                >
                                  <div className="section-container">
                                    <span className="mr-2">
                                      {getGrossMargin(
                                        quarterGroups[year][quarter]
                                          .total_products_cost,
                                        quarterGroups[year][quarter]
                                          .total_period_value
                                      ).toFixed(2)}
                                    </span>

                                    {quarterGroups[year - 1] !== undefined && (
                                      <ComparisonTooltip
                                        a={getGrossMargin(
                                          quarterGroups[year][quarter]
                                            .total_products_cost,
                                          quarterGroups[year][quarter]
                                            .total_period_value
                                        )}
                                        b={getGrossMargin(
                                          quarterGroups[year - 1][quarter]
                                            .total_products_cost,
                                          quarterGroups[year - 1][quarter]
                                            .total_period_value
                                        )}
                                      />
                                    )}
                                  </div>
                                </TableCell>
                              ))}
                              <TableCell style={{ backgroundColor: "#f7f7f7", minWidth: 150 }}>
                                {getMonthGrossMargin(
                                  Object.values(quarterGroups[year])
                                ).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>

                      <Table className="subtable">
                        <TableBody className="yearly-table-body">
                          {Object.keys(quarterGroups).map(year => (
                            <TableRow key={year}>
                              <FixedColumn>
                                <strong>{year}</strong>
                              </FixedColumn>
                              {Object.keys(quarterGroups[year]).map(quarter => (
                                <TableCell
                                  key={`${year}-${quarter}`}
                                  style={{
                                    minWidth: 150,
                                    backgroundColor: "#f7f7f7"
                                  }}
                                >
                                  <div className="section-container">
                                    <span className="mr-2">
                                      {getMarkUp(
                                        quarterGroups[year][quarter]
                                          .total_products_cost,
                                        quarterGroups[year][quarter]
                                          .total_period_value
                                      ).toFixed(2)}
                                    </span>

                                    {quarterGroups[year - 1] !== undefined && (
                                      <ComparisonTooltip
                                        a={getMarkUp(
                                          quarterGroups[year][quarter]
                                            .total_products_cost,
                                          quarterGroups[year][quarter]
                                            .total_period_value
                                        )}
                                        b={getMarkUp(
                                          quarterGroups[year - 1][quarter]
                                            .total_products_cost,
                                          quarterGroups[year - 1][quarter]
                                            .total_period_value
                                        )}
                                      />
                                    )}
                                  </div>
                                </TableCell>
                              ))}
                              <TableCell style={{ backgroundColor: "#f7f7f7", minWidth: 150 }}>
                                <div className="section-container">
                                  <span className="mr-2">
                                    {getMonthMarkUp(
                                      Object.values(quarterGroups[year])
                                    ).toFixed(2)}
                                  </span>

                                  {quarterGroups[year - 1] !== undefined && (
                                    <ComparisonTooltip
                                      a={getMonthMarkUp(
                                        Object.values(quarterGroups[year])
                                      )}
                                      b={getMonthMarkUp(
                                        Object.values(quarterGroups[year - 1])
                                      )}
                                    />
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                </>
              ))}
          </CardBody>
        </Card>
      </Grid>

      <SalesReceiptYearly vision={vision} setVision={setVision} />
    </>
  );
}
