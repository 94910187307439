import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../../_metronic/layout";
import useAuth from "../../hooks/auth";
import Error from "../Error";
import ApiAccess from "./Dashboard/ApiAccess";
import { isInvalidTokens } from "./Dashboard/ApiAccess/erpAuth";
import SaasBusinessSettings from "./Dashboard/SaasBusinessSettings";
import Stores from "./Dashboard/Stores";
import Strategic from "./Indicators/Strategic";
import ManagementPanel from "./ManagementPanel";
import ProductManagement from "./ProductManagement";
import WithoutTurn from "./WithoutTurn";

export default function SiteBasePage() {
  const auth = useAuth();

  if (!auth.user.saas_business || isInvalidTokens(auth.user.saas_business)) {
    return <Redirect to="/first-access" />;
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute exact path="/" component={ManagementPanel} />
        <ContentRoute exact path="/stores" component={Stores} />
        <ContentRoute
          exact
          path="/strategic/:period?/:indicator?"
          component={Strategic}
        />
        <ContentRoute exact path="/management" component={ProductManagement} />
        <ContentRoute
          exact
          path="/management/without-turn"
          component={WithoutTurn}
        />
        <ContentRoute
          exact
          path="/management/:curve?/:interval?/:analysisType?"
          component={ProductManagement}
        />
        <ContentRoute exact path="/account/api_access" component={ApiAccess} />
        <ContentRoute exact path="/account/saas_business" component={SaasBusinessSettings} />
        <ContentRoute path="*" component={() => {
          return <Error status={404} text="Página não encontrada" />
        }}/>
      </Switch>
    </Suspense>
  );
}
