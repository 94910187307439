import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField
} from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useSubheader } from "../../../../_metronic/layout";
import CnpjInputMask from "../../../../components/Common/Masks/CnpjInputMask";
import { createYupErrorsObject, getOnlyNumbers } from "../../../../helpers";
import useAuth from "../../../../hooks/auth";
import * as auth from "../../../../redux/Auth";
import { create } from "../../../../services/businesses";
import ErpAuth, { ERPs, isInvalidTokens } from "../ApiAccess/erpAuth";
import Import from "./import";
import schema from "./schema";
import "./styles.scss";

const INITIAL_DATA = {
  name: "",
  fantasy_name: "",
  cnpj: "",
  state_registration: "",
  coverage: 30,
  used_erp: ""
};

function FirstAccess({ createBusiness }) {
  const subheader = useSubheader();
  subheader.setTitle("Primeiro acesso");

  const [data, setData] = useState(INITIAL_DATA);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [usedErp, setUsedErp] = useState({});

  const auth = useAuth();
  if (auth.user?.saas_business && !isInvalidTokens(auth.user.saas_business)) {
    return <Import />;
  } else if (auth.user?.saas_business && isInvalidTokens(auth.user.saas_business)) {
    const uErp = ERPs.find(e => e.key === auth.user.saas_business.used_erp);
    window.location.href = uErp.url(auth.user.saas_business.uuid);
    return null;
  }

  const handleSelectErp = (erp) => {
    setData({ ...data, used_erp: erp.key });
    setUsedErp(erp);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    try {
      const fields = await schema.validate(data, { abortEarly: false });
      fields.cnpj = getOnlyNumbers(fields.cnpj);
      const res = await create(fields);

      createBusiness(res.data);
      window.location.href = usedErp.url(res.data.uuid);
    } catch (e) {
      if (e.name === "ValidationError" && e.inner) {
        setErrors(createYupErrorsObject(e));
      } else {
        setErrors(e.response.data.errors);
      }
    }

    setLoading(false);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Card className="card-heavy-border">
          <CardHeader subheader="Complete os dados da sua empresa" />

          <CardContent>
            <Grid
              component="form"
              container
              spacing={4}
              onSubmit={handleSubmit}
            >
              <Grid item xs={12}>
                <TextField
                  label="Razão Social"
                  variant="outlined"
                  value={data.name}
                  error={errors.name}
                  helperText={errors.name}
                  onChange={e => setData({ ...data, name: e.target.value })}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Nome fantasia"
                  variant="outlined"
                  error={errors.fantasy_name}
                  helperText={errors.fantasy_name}
                  value={data.fantasy_name}
                  onChange={e =>
                    setData({ ...data, fantasy_name: e.target.value })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="CNPJ"
                  variant="outlined"
                  error={errors.cnpj}
                  helperText={errors.cnpj}
                  value={data.cnpj}
                  onChange={e => setData({ ...data, cnpj: e.target.value })}
                  InputProps={{
                    inputComponent: CnpjInputMask
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Inscrição Estadual"
                  variant="outlined"
                  error={errors.state_registration}
                  helperText={errors.state_registration}
                  value={data.state_registration}
                  onChange={e =>
                    setData({ ...data, state_registration: e.target.value })
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Semanas de cobertura de estoque"
                  variant="outlined"
                  error={errors.coverage}
                  helperText={errors.coverage}
                  value={data.coverage}
                  onChange={e => setData({ ...data, coverage: e.target.value })}
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <ErpAuth
          erp={usedErp?.key ?? ''}
          error={errors.used_erp}
          handleSelect={handleSelectErp}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          color="secondary"
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          disabled={loading}
          size="large"
          fullWidth
        >
          {loading ? "Carregando..." : "Sincronizar ERP"}
        </Button>
      </Grid>
    </Grid>
  );
}

export default connect(null, {
  createBusiness: auth.actions.createBusiness
})(FirstAccess);
