import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from "@material-ui/core";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import React from "react";
import {
  Card,
  CardBody,
  CardHeader, CardHeaderTitle
} from '../../../../_metronic/_partials/controls';

export const isInvalidTokens = (saas_business) => {
  if (saas_business.used_erp === 'bling') {
    return !saas_business.bling_access_token || !saas_business.bling_refresh_token;
  }
  return true;
}

const useStyles = makeStyles(theme => ({
  inputContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  }
}));

// TODO: Implement fetch from API
export const ERPs = [{
  key: "bling",
  title: "Bling",
  url: (uuid) => `https://www.bling.com.br/Api/v3/oauth/authorize?response_type=code&client_id=${BLING_CLIENT_ID}&state=${uuid}`
}];

const BLING_CLIENT_ID = process.env.REACT_APP_BLING_CLIENT_ID || "";

export default function ErpAuth({ erp, error, handleSelect }) {
  const classes = useStyles();

  const handleSelectErp = el => {
    handleSelect(
      ERPs.find(e => e.key === el.target.value)
    );
  }

  return (
    <Card className="card-heavy-border">
      <CardHeader title={
        <CardHeaderTitle className="card-header-with-icon">
          <VerifiedUserIcon />
          <span>
            Realize a autenticação com o ERP
          </span>
        </CardHeaderTitle>
      } />

      <CardBody className={classes.inputContainer}>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth className="filter-container">
            <InputLabel id="select-erp">Selecione o ERP</InputLabel>
            <Select
              label="ERP"
              labelId="select-erp"
              value={erp}
              error={error}
              onChange={handleSelectErp}
              style={{ minWidth: "200px" }}
              fullWidth
            >
              {ERPs.map(({ key, title }) => (
                <MenuItem key={key} value={key}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </CardBody>
    </Card>
  );
}
