import qs from "qs";
import api, { useFetch } from "./api";

export function getNeedToBuyProducts(
  page = 1,
  storesId,
  weeksInterval,
  curve,
  analysisType,
  brand = null,
  buyNecessity = true,
  category = null,
  subCategory = null,
  segment = null
) {
  return api.get(`/management/need-to-buy?page=${page}`, {
    params: {
      stores: storesId,
      weeks: weeksInterval,
      curve: curve,
      type: analysisType,
      only_with_buy_necessity: buyNecessity,
      brand,
      subcategory: subCategory,
      category,
      segment
    }
  });
}

export function getProductsWithoutTurn(
  page = 1,
  storesId,
  weeksInterval,
  brand,
  category,
  subCategory,
  segment
) {
  return api.get(`/management/without-turn/product`, {
    params: {
      stores: storesId,
      weeks: weeksInterval,
      subcategory: subCategory,
      category,
      segment,
      brand,
      page
    }
  });
}

export function getBrandsWithoutTurn(
  page = 1,
  storesId,
  weeksInterval,
  category,
  subCategory,
  segment,
  orderBy,
  orderDirection
) {
  return api.get(`/management/without-turn/brand?page=${page}`, {
    params: {
      stores: storesId,
      weeks: weeksInterval,
      category,
      subCategory,
      segment,
      order_by: orderBy,
      order: orderDirection
    }
  });
}

export function exportToWithoutTurn(
  extension,
  storesId,
  weeksInterval,
  category,
  subCategory,
  segment
) {
  return api.get(`/management/without-turn/export/${extension}`, {
    params: {
      stores: storesId,
      weeks: weeksInterval,
      category,
      subcategory: subCategory,
      segment
    },
    responseType: "blob"
  });
}

export function exportToNeedToBuy(
  extension,
  storesId,
  weeksInterval,
  curve,
  category,
  subCategory,
  segment,
  brand = null,
  withBuyNecessity = true
) {
  return api.get(`/management/need-to-buy/export/${extension}`, {
    params: {
      stores: storesId,
      weeks: weeksInterval,
      curve: curve,
      category,
      subcategory: subCategory,
      segment,
      brand,
      only_with_buy_necessity: withBuyNecessity ? 1 : 0
    },
    responseType: "blob"
  });
}

export function getLowTurnProducts(
  page = 1,
  storesId,
  weeksInterval,
  brand = null,
  category = null,
  subCategory = null,
  segment = null
) {
  return api.get(`/management/low-turn-products`, {
    params: {
      page,
      stores: storesId,
      weeks: weeksInterval,
      subcategory: subCategory,
      brand,
      category
    }
  });
}

export function exportLowTurnProducts(
  extension,
  storesId,
  weeksInterval,
  category,
  subCategory,
  segment
) {
  return api.get(`/management/low-turn-products/export/${extension}`, {
    params: {
      stores: storesId,
      weeks: weeksInterval,
      subcategory: subCategory,
      category,
      segment
    },
    responseType: "blob"
  });
}

export function getLowTurnBrands(
  page = 1,
  storesId,
  weeksInterval,
  orderBy,
  orderDirection
) {
  return api.get(`/management/low-turn-brands`, {
    params: {
      page,
      stores: storesId,
      weeks: weeksInterval,
      order_by: orderBy,
      order: orderDirection
    }
  });
}

export function useLowTurnBrands(params) {
  const queryString = qs.stringify(params);

  return useFetch("get", `management/low-turn-brands?${queryString}`);
}

export function useLowTurnProducts(params) {
  const queryString = qs.stringify(params);

  return useFetch("get", `management/low-turn-products?${queryString}`);
}
